html.no-webp {
  body.home {
    > header {
      background-image: url(#{map-get($path, hero)}/home.jpg);
      @include background-image-2x('#{map-get($path, hero)}/home2x.jpg');
    }
  }

  body.contact {
    > header {
      background-image: url(#{map-get($path, hero)}/contact.jpg);
      @include background-image-2x('#{map-get($path, hero)}/contact2x.jpg');
    }
  }

  body.privacy,
  body.terms {
    header {
      background-image: url(#{map-get($path, hero)}/privacy.jpg);
      @include background-image-2x('#{map-get($path, hero)}/privacy2x.jpg');

      @include media('<phone') {
        background-position: top left;
      }
    }
  }
}

html.webp {
  body.home {
    > header {
      background-image: url(#{map-get($path, hero)}/home.webp);
      @include background-image-2x('#{map-get($path, hero)}/home2x.webp');
    }
  }

  body.contact {
    > header {
      background-image: url(#{map-get($path, hero)}/contact.webp);
      @include background-image-2x('#{map-get($path, hero)}/contact2x.webp');
    }
  }

  body.privacy,
  body.terms {
    header {
      background-image: url(#{map-get($path, hero)}/privacy.webp);
      @include background-image-2x('#{map-get($path, hero)}/privacy2x.webp');

      @include media('<phone') {
        background-position: top left;
      }
    }
  }

}
