nav {
  position: relative;
  z-index: 10;
  padding: 30px 30px 0;

  @include media('<phone') {
    padding: 20px 20px 0;
  }

  &:after {
    @extend .clearfix;
  }

  // Weissman logo (top left)
  a.logo {
    width: 168px;
    height: 30px;
    background: url(#{map-get($path, logo)}/logo.svg) no-repeat;
    background-position: 0 0;
    background-size: cover;
    display: inline-block;
    text-indent: -9999px;
    position: relative;
    top: 7px;
    float: left;

    .light & {
      background-position: 0 -30px;
    }

    @include media('<phone') {
      width: 50px;
      height: 50px;
      background: url(#{map-get($path, logo)}/w-device.svg) no-repeat;
      background-position: 0 0;
      background-size: cover;
      display: inline-block;
      text-indent: -9999px;
      position: relative;
      margin-bottom: 30px;

      .light & {
        background-position: 0 -50px;
      }
    }
  }

  // Get in touch button (top right)
  .btn {
    float: right;

    @include media('<phone') {
      display: none;
    }
  }
}
