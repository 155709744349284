@font-face {
    font-family: 'avenir_lt_std_roman';
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    src: url('#{map-get($path, font)}/avenir_lt_std_roman/avenir_lt_std_roman.eot');
    src: url('#{map-get($path, font)}/avenir_lt_std_roman/avenir_lt_std_roman.eot?#iefix') format('embedded-opentype'),
         url('#{map-get($path, font)}/avenir_lt_std_roman/avenir_lt_std_roman.woff2') format('woff2'),
         url('#{map-get($path, font)}/avenir_lt_std_roman/avenir_lt_std_roman.woff') format('woff'),
         url('#{map-get($path, font)}/avenir_lt_std_roman/avenir_lt_std_roman.ttf') format('truetype'),
         url('#{map-get($path, font)}/avenir_lt_std_roman/avenir_lt_std_roman.svg#avenir_lt_std_roman') format('svg');
}

@font-face {
    font-family: 'gazette_lh_roman';
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    src: url('#{map-get($path, font)}/gazette_lh_roman/gazette_lh_roman.eot');
    src: url('#{map-get($path, font)}/gazette_lh_roman/gazette_lh_roman.eot?#iefix') format('embedded-opentype'),
         url('#{map-get($path, font)}/gazette_lh_roman/gazette_lh_roman.woff2') format('woff2'),
         url('#{map-get($path, font)}/gazette_lh_roman/gazette_lh_roman.woff') format('woff'),
         url('#{map-get($path, font)}/gazette_lh_roman/gazette_lh_roman.ttf') format('truetype'),
         url('#{map-get($path, font)}/gazette_lh_roman/gazette_lh_roman.svg#gazette_lhroman') format('svg');
}
