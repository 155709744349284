.icon {
  width: 30px;
  height: 30px;
  background: url(#{map-get($path, icon)}/icons.svg) no-repeat;
  background-position: 0 0;
  background-size: 150px;
  display: inline-block;
  text-indent: -9999px;
  position: relative;

  @include media('<phone') {
    width: 60px;
    height: 60px;
    background-size: 265px;
  }

  &.cd {
    background-position: -30px -32px;

    @include media('<phone') {
      background-position: -50px -56px;
    }
  }

  &.wd {
    background-position: -60px -32px;

    @include media('<phone') {
      background-position: -105px -51px;
    }
  }

  &.vr {
    background-position: -96px -32px;

    @include media('<phone') {
      background-position: -167px -51px;
    }
  }

  &.arrow {
    background-position: -125px -33px;

    .light & {
      background-position: -125px -66px;
    }
  }

  &.ux {
    background-position: 0 -34px;

    @include media('<phone') {
      background-position: 5px -56px;
    }
  }

  &.dc {
    background-position: 0 -66px;

    @include media('<phone') {
      background-position: 7px -111px;
    }
  }

  &.dm {
    background-position: -32px 0;

    @include media('<phone') {
      background-position: -50px 7px;
    }
  }

  &.ma {
    background-position: -63px 0;

    @include media('<phone') {
      background-position: -106px 7px;
    }
  }

  &.ex {
    background-position: -96px 0;

    @include media('<phone') {
      background-position: -164px 7px;
    }
  }

  &.ds {
    background-position: -127px 0;

    @include media('<phone') {
      background-position: -221px 7px;
    }
  }

  &.br {
    @include media('<phone') {
      background-position: 8px 5px;
    }
  }

  &.twt {
    background-position: -65px -66px;

    @include media('<phone') {
      background-position: -112px -115px;
    }
  }

  &.lnk {
    background-position: -30px -67px;

    @include media('<phone') {
      background-position: -51px -119px;
    }
  }
}
