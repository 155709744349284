// general button styling
.btn {
  background: transparent;
  font-family: $body;
  font-size: 0.938em;
  font-style: normal;
  line-height: 1em;
  color: black;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  border: 1px solid black;
  padding: 0.875em 1em;

  &:hover {
    color: black;
  }

  .light & {
    color: white;
    border: 1px solid white;

    &:hover {
      color: white;
    }

    &.opaque {
      background: black;
    }
  }

  &.opaque {
    background: white;
  }
}
