html, body, p, h1, h2, h3, ul, ol, li, blockquote {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  text-align: center;
}

p, li {
  font-family: $body;
  font-style: normal;
  text-align: left;
  @include text-size-adjust(100%);
}

h1, h2, h3, h4, h5, h6, blockquote {
  font-family: $heading;
  font-style: normal;
  text-align: left;
  @include text-size-adjust(100%);
}

h1, h2, h3 {
  font-weight: normal;
  line-height: 1em;
  color: #{map-get($color, black)};
}

p, li {
  font-size: 1.1em;
  line-height: 1.7em;
  color: #{map-get($color, black)};
  padding-bottom: 20px;

  @include media('>=phone', '<tablet') {
    font-size: 1em;
  }

  @include media('<phone') {
    font-size: 1.2em;
  }
}

h1, h2 {
  font-size: 2.3em;

  @include media('<=tiny') {
    font-size: 1.8em;
  }
}

h2 {
  @include media('>=phone', '<tablet') {
    font-size: 2em;
  }
}

h3 {
  font-size: 1em;
}

blockquote {
  font-size: 1.7em;

  @include media('>=phone', '<tablet') {
    font-size: 1.5em;
  }
}

a {
  color: #{map-get($color, deep-steel)};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include media('>=phone') {
    &:hover {
      color: #{map-get($color, light-steel)};
    }
  }
}

img {
  display: block;
}
