@font-face {
  font-family: "avenir_lt_std_roman";
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  src: url("/fonts/avenir_lt_std_roman/avenir_lt_std_roman.eot");
  src: url("/fonts/avenir_lt_std_roman/avenir_lt_std_roman.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir_lt_std_roman/avenir_lt_std_roman.woff2") format("woff2"), url("/fonts/avenir_lt_std_roman/avenir_lt_std_roman.woff") format("woff"), url("/fonts/avenir_lt_std_roman/avenir_lt_std_roman.ttf") format("truetype"), url("/fonts/avenir_lt_std_roman/avenir_lt_std_roman.svg#avenir_lt_std_roman") format("svg");
}
@font-face {
  font-family: "gazette_lh_roman";
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  src: url("/fonts/gazette_lh_roman/gazette_lh_roman.eot");
  src: url("/fonts/gazette_lh_roman/gazette_lh_roman.eot?#iefix") format("embedded-opentype"), url("/fonts/gazette_lh_roman/gazette_lh_roman.woff2") format("woff2"), url("/fonts/gazette_lh_roman/gazette_lh_roman.woff") format("woff"), url("/fonts/gazette_lh_roman/gazette_lh_roman.ttf") format("truetype"), url("/fonts/gazette_lh_roman/gazette_lh_roman.svg#gazette_lhroman") format("svg");
}
html, body, p, h1, h2, h3, ul, ol, li, blockquote {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  text-align: center;
}

p, li {
  font-family: "avenir_lt_std_roman", Times, serif;
  font-style: normal;
  text-align: left;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6, blockquote {
  font-family: "gazette_lh_roman", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

h1, h2, h3 {
  font-weight: normal;
  line-height: 1em;
}

p, li {
  font-size: 1.1em;
  line-height: 1.7em;
  padding-bottom: 20px;
}
@media (min-width: 640px) and (max-width: 1023px) {
  p, li {
    font-size: 1em;
  }
}
@media (max-width: 639px) {
  p, li {
    font-size: 1.2em;
  }
}

h1, h2 {
  font-size: 2.3em;
}
@media (max-width: 320px) {
  h1, h2 {
    font-size: 1.8em;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  h2 {
    font-size: 2em;
  }
}

h3 {
  font-size: 1em;
}

blockquote {
  font-size: 1.7em;
}
@media (min-width: 640px) and (max-width: 1023px) {
  blockquote {
    font-size: 1.5em;
  }
}

a {
  color: #364B54;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (min-width: 640px) {
  a:hover {
    color: #9CBBCB;
  }
}

img {
  display: block;
}

nav {
  position: relative;
  z-index: 10;
  padding: 30px 30px 0;
}
@media (max-width: 639px) {
  nav {
    padding: 20px 20px 0;
  }
}
nav a.logo {
  width: 168px;
  height: 30px;
  background: url(/img/logo/logo.svg) no-repeat;
  background-position: 0 0;
  background-size: cover;
  display: inline-block;
  text-indent: -9999px;
  position: relative;
  top: 7px;
  float: left;
}
.light nav a.logo {
  background-position: 0 -30px;
}
@media (max-width: 639px) {
  nav a.logo {
    width: 50px;
    height: 50px;
    background: url(/img/logo/w-device.svg) no-repeat;
    background-position: 0 0;
    background-size: cover;
    display: inline-block;
    text-indent: -9999px;
    position: relative;
    margin-bottom: 30px;
  }
  .light nav a.logo {
    background-position: 0 -50px;
  }
}
nav .btn {
  float: right;
}
@media (max-width: 639px) {
  nav .btn {
    display: none;
  }
}

body > header {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
}
body > header .headertext {
  width: 30%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 20%;
  z-index: 10;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 639px) {
  body > header .headertext {
    width: 60%;
    top: auto;
    bottom: 140px;
    left: 20px;
    transform: none;
  }
}
body > header .headertext h1 {
  line-height: 1.4em;
  color: #364B54;
  padding: 0;
}
@media (max-width: 639px) {
  body > header .headertext h1 {
    line-height: 1.2em;
    color: white;
  }
}
body > header .arrow {
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: 10;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 639px) {
  body > header .arrow {
    display: none;
  }
}

html.no-webp body.home > header {
  background-image: url(/img/hero/home.jpg);
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  html.no-webp body.home > header {
    background-image: url("/img/hero/home2x.jpg");
  }
}
html.no-webp body.contact > header {
  background-image: url(/img/hero/contact.jpg);
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  html.no-webp body.contact > header {
    background-image: url("/img/hero/contact2x.jpg");
  }
}
html.no-webp body.privacy header,
html.no-webp body.terms header {
  background-image: url(/img/hero/privacy.jpg);
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  html.no-webp body.privacy header,
html.no-webp body.terms header {
    background-image: url("/img/hero/privacy2x.jpg");
  }
}
@media (max-width: 639px) {
  html.no-webp body.privacy header,
html.no-webp body.terms header {
    background-position: top left;
  }
}

html.webp body.home > header {
  background-image: url(/img/hero/home.webp);
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  html.webp body.home > header {
    background-image: url("/img/hero/home2x.webp");
  }
}
html.webp body.contact > header {
  background-image: url(/img/hero/contact.webp);
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  html.webp body.contact > header {
    background-image: url("/img/hero/contact2x.webp");
  }
}
html.webp body.privacy header,
html.webp body.terms header {
  background-image: url(/img/hero/privacy.webp);
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  html.webp body.privacy header,
html.webp body.terms header {
    background-image: url("/img/hero/privacy2x.webp");
  }
}
@media (max-width: 639px) {
  html.webp body.privacy header,
html.webp body.terms header {
    background-position: top left;
  }
}

.btn {
  background: transparent;
  font-family: "avenir_lt_std_roman", Times, serif;
  font-size: 0.938em;
  font-style: normal;
  line-height: 1em;
  color: black;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  border: 1px solid black;
  padding: 0.875em 1em;
}
.btn:hover {
  color: black;
}
.light .btn {
  color: white;
  border: 1px solid white;
}
.light .btn:hover {
  color: white;
}
.light .btn.opaque {
  background: black;
}
.btn.opaque {
  background: white;
}

.clearfix, nav:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.icon {
  width: 30px;
  height: 30px;
  background: url(/img/icon/icons.svg) no-repeat;
  background-position: 0 0;
  background-size: 150px;
  display: inline-block;
  text-indent: -9999px;
  position: relative;
}
@media (max-width: 639px) {
  .icon {
    width: 60px;
    height: 60px;
    background-size: 265px;
  }
}
.icon.cd {
  background-position: -30px -32px;
}
@media (max-width: 639px) {
  .icon.cd {
    background-position: -50px -56px;
  }
}
.icon.wd {
  background-position: -60px -32px;
}
@media (max-width: 639px) {
  .icon.wd {
    background-position: -105px -51px;
  }
}
.icon.vr {
  background-position: -96px -32px;
}
@media (max-width: 639px) {
  .icon.vr {
    background-position: -167px -51px;
  }
}
.icon.arrow {
  background-position: -125px -33px;
}
.light .icon.arrow {
  background-position: -125px -66px;
}
.icon.ux {
  background-position: 0 -34px;
}
@media (max-width: 639px) {
  .icon.ux {
    background-position: 5px -56px;
  }
}
.icon.dc {
  background-position: 0 -66px;
}
@media (max-width: 639px) {
  .icon.dc {
    background-position: 7px -111px;
  }
}
.icon.dm {
  background-position: -32px 0;
}
@media (max-width: 639px) {
  .icon.dm {
    background-position: -50px 7px;
  }
}
.icon.ma {
  background-position: -63px 0;
}
@media (max-width: 639px) {
  .icon.ma {
    background-position: -106px 7px;
  }
}
.icon.ex {
  background-position: -96px 0;
}
@media (max-width: 639px) {
  .icon.ex {
    background-position: -164px 7px;
  }
}
.icon.ds {
  background-position: -127px 0;
}
@media (max-width: 639px) {
  .icon.ds {
    background-position: -221px 7px;
  }
}
@media (max-width: 639px) {
  .icon.br {
    background-position: 8px 5px;
  }
}
.icon.twt {
  background-position: -65px -66px;
}
@media (max-width: 639px) {
  .icon.twt {
    background-position: -112px -115px;
  }
}
.icon.lnk {
  background-position: -30px -67px;
}
@media (max-width: 639px) {
  .icon.lnk {
    background-position: -51px -119px;
  }
}