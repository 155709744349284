body > header {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;

  // container for the header text
  .headertext {
    width: 30%;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 20%;
    z-index: 10;
    @include transform(translate(-50%, -50%));

    @include media('<phone') {
      width: 60%;
      top: auto;
      bottom: 140px;
      left: 20px;
      transform: none;
    }

    // header text
    h1 {
      line-height: 1.4em;
      color: #{map-get($color, deep-steel)};
      padding: 0;

      @include media('<phone') {
        line-height: 1.2em;
        color: white;
      }
    }
  }

  // down arrow at the bottom of the header
  .arrow {
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 10;
    @include transform(translate(-50%, -50%));

    @include media('<phone') {
      display: none;
    }
  }
}
