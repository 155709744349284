// border radius
@mixin border-radius($radius) {
     -webkit-appearance: none;
  -webkit-border-radius: $radius;
          border-radius: $radius;
}

// text shadow
@mixin stroke($width, $color) {
  $shadow: ();
  @for $i from -$width through $width {
    $shadow: append($shadow,#{$width}px #{$i}px $color, comma);
    $shadow: append($shadow,#{-$width}px #{$i}px $color, comma);
    $shadow: append($shadow,#{$i}px #{$width}px $color, comma);
    $shadow: append($shadow,#{$i}px #{-$width}px $color, comma);
  }
  text-shadow: $shadow;
}

// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

// animation
@mixin animation($animation) {
	   -moz-animation: $animation;
	     -o-animation: $animation;
	    -ms-animation: $animation;
	-webkit-animation: $animation;
          animation: $animation;
}

// animation fill mode
@mixin animation-fill-mode($fill-mode) {
     -moz-animation-fill-mode: $fill-mode;
       -o-animation-fill-mode: $fill-mode;
  -webkit-animation-fill-mode: $fill-mode;
          animation-fill-mode: $fill-mode;
}

// keyframes animation
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

// transitions
@mixin transition($transition) {
     -moz-transition: $transition;
       -o-transition: $transition;
      -ms-transition: $transition;
  -webkit-transition: $transition;
          transition: $transition;
}

// box shadow
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
       -moz-box-shadow:inset $top $left $blur $spread $color;
            box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
       -moz-box-shadow: $top $left $blur $spread $color;
            box-shadow: $top $left $blur $spread $color;
  }
}

// adjust text size
@mixin text-size-adjust($value: none) {
     -moz-text-size-adjust: $value;
      -ms-text-size-adjust: $value;
  -webkit-text-size-adjust: $value;
          text-size-adjust: $value;
}

// retina background image
@mixin background-image-2x($image) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {

    background-image: url($image);
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
